
import * as ipxRuntime$NfPDqQFVeP from '/var/www/worksnap/nuxt-frontend/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "s3.eu-central-1.amazonaws.com"
  ],
  "alias": {
    "/amazon": "https://s3.eu-central-1.amazonaws.com"
  }
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$NfPDqQFVeP, defaults: {"modifiers":{"quality":"95","format":"webp"},"name":"ipx","provider":"/var/www/worksnap/nuxt-frontend/node_modules/@nuxt/image/dist/runtime/providers/ipx"} }
}
        